export const pageSettings = {
  "/login": {
    center: true,
    showStepper: false,
  },
  "/auth": {
    center: true,
    showStepper: false,
  },
  "/review": {
    center: false,
    showStepper: true,
  },
  "/schedule": {
    center: true,
    showStepper: true,
  },
  "/trips": {
    center: false,
    showStepper: false,
  },
  "/trip": {
    center: false,
    showStepper: true,
  },
  "/seller-portal": {
    center: true,
    showStepper: false,
  },
  "/checkout": {
    center: true,
    showStepper: true,
  },
  "/payment-confirmation": {
    center: true,
    showStepper: true,
  },
  "/pay-seller": {
    center: false,
    showStepper: false,
    content: true,
  },
  "/item-inspection": {
    center: false,
    showStepper: false,
    content: true,
  },
  "/": {
    center: false,
    showStepper: false,
    content: true,
  },
  "/privacy-policy": {
    center: false,
    showStepper: false,
    content: true,
  },
  "/terms-of-service": {
    center: false,
    showStepper: false,
    content: true,
  },
  "/contact": {
    center: true,
    showStepper: false,
    content: true,
  },
  "/page-not-found": {
    center: true,
    showStepper: false,
    content: true,
  },
  "/service-area": {
    center: true,
    showStepper: false,
    content: true,
  },
};

export const getPageSettingProperty = (pathname, property) => {
  // Check if the pathname exists in pageSettings
  if (pageSettings[pathname]) {
    // Return the value of the specified property
    return pageSettings[pathname][property];
  } else {
    if (property === "center") {
      return true;
    } else if (property === "showStepper") {
      return false;
    } else if (property === "content") {
      return false;
    }
  }
};
