import { useNavigate, useLocation } from "react-router-dom";

const useCustomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const customNavigation = (
    newPath,
    urlStates,
    deleteParams,
    stage,
    deleteAll
  ) => {
    // Extract the hash from the newPath if it exists
    const [pathname, hash] = newPath.split("#");
    const searchParams = new URLSearchParams(location.search);

    // Handle deleting all query parameters
    if (deleteAll) {
      searchParams.forEach((_, key) => {
        searchParams.delete(key);
      });
    }

    // Delete specified query parameters
    if (deleteParams) {
      deleteParams.forEach((param) => {
        searchParams.delete(param);
      });
    }

    // Set the stage parameter if stage is a number
    if (typeof stage === "number") {
      searchParams.set("stage", stage.toString());
    }

    // Serialize urlStates.data and add/update to searchParams if urlStates is not null
    if (urlStates && urlStates.data) {
      Object.entries(urlStates.data).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          if (typeof value === "object") {
            searchParams.set(key, JSON.stringify(value));
          } else {
            searchParams.set(key, value.toString());
          }
        }
      });
    }

    // Perform navigation with updated search parameters and hash
    navigate({
      pathname: pathname || location.pathname,
      search: searchParams.toString(),
      hash: hash ? `#${hash}` : "",
    });
  };

  return customNavigation;
};

export default useCustomNavigation;
