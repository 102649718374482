import React from "react";
import SmLightText from "../../text/SmLightText";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900 mx-4">
      <div className="w-full max-w-screen-xl mx-auto py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a
            href="/"
            className="flex items-center justify-center md:justify-start mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img
              src="/images/marketing/delivery-truck-side.png"
              className="h-6"
              alt="delivery truck"
            />
            <span className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">
              KW Pickup
            </span>
          </a>
          <ul className="flex flex-wrap items-center justify-center md:justify-start mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a
                href="/#service-overview"
                className="hover:underline me-4 md:me-6"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/privacy-policy"
                className="hover:underline me-4 md:me-6"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="/terms-of-service"
                className="hover:underline me-4 md:me-6"
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a href="/contact" className="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex justify-center">
          <SmLightText>
            <div className="text-center">
              {" "}
              © 2024{" "}
              <a href="/" className="hover:underline">
                KW Pickup™
              </a>
              . All Rights Reserved.
            </div>
          </SmLightText>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
