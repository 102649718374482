import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "flowbite/dist/flowbite.css";
import ReactGA from "react-ga4";

// Initialize Google Analytics 4
ReactGA.initialize("G-HBYF34G7ZS"); // Replace with your Measurement ID
ReactGA.send("pageview");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />); // Render App directly without StrictMode

reportWebVitals();
