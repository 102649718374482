import React from "react";

const CondsensedSecBtn = ({ children, action, className }) => {
  return (
    <button
      type="button"
      className={`text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 ${className}`}
      onClick={action}
    >
      <div className="flex justify-center items-center">{children}</div>
    </button>
  );
};

export default CondsensedSecBtn;
