import { jwtDecode } from "jwt-decode";

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const isTokenValid = () => {
  const token = getToken();
  if (!token) return false;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
    return decodedToken.exp > currentTime;
  } catch (error) {
    return false;
  }
};

export const decodeToken = () => {
  const token = getToken();
  if (!token) return null;

  try {
    return jwtDecode(token);
  } catch (error) {
    console.error("Invalid token");
    return null;
  }
};

export const deleteToken = () => {
  localStorage.removeItem("accessToken");
  window.dispatchEvent(new Event("tokenChanged"));
};

export const setToken = (token) => {
  localStorage.setItem("accessToken", token);
  window.dispatchEvent(new Event("tokenChanged"));
};
