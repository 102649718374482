import React, { useState, useEffect, useRef, useContext } from "react";
import CondsensedPriBtn from "../../../buttons/CondsensedPriBtn";
import CondsensedSecBtn from "../../../buttons/CondsensedSecBtn";
import useCustomNavigation from "../../../../utils/hooks/useCustomNavigation";
import {
  deleteToken,
  decodeToken,
} from "../../../../utils/authentication/jwtAuth";
import { formatPhoneNumber } from "../../../../utils/helpers/data-processing/formatPhoneNumber";
import { AuthContext } from "../../../../utils/authentication/AuthContext";

const Navbar = ({ showStepper }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const { loggedIn, setLoggedIn } = useContext(AuthContext);
  const customNavigation = useCustomNavigation();

  const handleLogout = () => {
    deleteToken();
    setLoggedIn(false);
    handleMenuItemClick("/");
  };

  const handleMenuToggle = (event) => {
    event.stopPropagation();
    setIsMenuOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  const handleMenuItemClick = (linkTo) => {
    setIsMenuOpen(false);
    customNavigation(linkTo);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const menuElements = [
    { text: "About", linkTo: "/#service-overview" },
    { text: "Area of Service", linkTo: "/service-area" },
    { text: "Seller Payment", linkTo: "/pay-seller" },
    { text: "Item Inspection", linkTo: "/item-inspection" },
  ];

  return (
    <header>
      <nav className="border-gray-200 py-2.5 relative z-20">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <div>
            <a href="/" className={`flex items-center`}>
              <img
                src="/images/marketing/delivery-truck-side.png"
                className="mr-3 h-6"
                alt="KW Pickup Logo"
              />
              <span
                className={`${
                  showStepper ? "hidden md:flex" : ""
                } self-center text-xl font-semibold whitespace-nowrap`}
              >
                KW Pickup
              </span>
            </a>
          </div>
          <div className="flex items-center space-x-2 md:order-2">
            {!loggedIn && (
              <div className="hidden lg:flex gap-2">
                <CondsensedSecBtn action={() => customNavigation("/login")}>
                  Log In
                </CondsensedSecBtn>
                <CondsensedPriBtn
                  action={() => customNavigation("/estimate")}
                  enabled={true}
                >
                  + New Pickup
                </CondsensedPriBtn>
              </div>
            )}
            <button
              onClick={handleMenuToggle}
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded={isMenuOpen}
              ref={buttonRef}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`w-6 h-6 ${isMenuOpen ? "hidden" : "block"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className={`w-6 h-6 ${isMenuOpen ? "block" : "hidden"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            ref={menuRef}
            className={`${
              isMenuOpen ? "block" : "hidden"
            } absolute top-full right-0 w-64 z-50 bg-white divide-y divide-gray-100 rounded-lg shadow`}
            id="mobile-menu-2"
          >
            {loggedIn ? (
              <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                <div>Logged in as:</div>
                <div className="font-medium truncate">
                  {formatPhoneNumber(decodeToken().phoneNumber)}
                </div>
              </div>
            ) : (
              <ul
                className="lg:hidden py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownInformationButton"
              >
                <li className={`mb-2`}>
                  <a
                    href={"/login"}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Log In
                  </a>
                </li>
              </ul>
            )}
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownInformationButton"
            >
              {loggedIn && (
                <li className={`mb-2`}>
                  <a
                    href={"/trips"}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    My Pickups
                  </a>
                </li>
              )}
              {menuElements.map((element) => (
                <li
                  key={element.text}
                  className={`mb-2 ${element.hidden && "lg:hidden"}`}
                >
                  <a
                    href={element.linkTo}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => handleMenuItemClick(element.linkTo)}
                  >
                    {element.text}
                  </a>
                </li>
              ))}
              {loggedIn && (
                <a
                  href="/"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                  onClick={handleLogout}
                >
                  Log out
                </a>
              )}
            </ul>

            <div className="py-2">
              <a
                href="/"
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              >
                <img
                  src="/images/marketing/delivery-truck-side.png"
                  className="mr-3 h-4"
                  alt="KW Pickup Logo"
                />
                KW Pickup
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
