import React from "react";

const CondsensedPriBtn = ({ className, children, action, enabled }) => {
  return (
    <button
      type="button"
      className={`text-white font-medium rounded-lg text-sm px-5 py-2.5 ${
        enabled
          ? "bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
          : "bg-blue-400 cursor-not-allowed dark:bg-blue-500"
      } ${className}`}
      disabled={!enabled}
      onClick={action}
    >
      <div className="flex justify-center items-center w-full">{children}</div>
    </button>
  );
};

export default CondsensedPriBtn;
