import "./App.css";
import React, { useEffect } from "react";
import { AuthProvider } from "./utils/authentication/AuthContext";
import { BrowserRouter, useLocation } from "react-router-dom";
import Global from "./components/layout-elements/Global";
import AnimatedRoutes from "./components/pages/AnimatedRoutes";
import ReactGA from "react-ga4";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send("pageview", { path: location.pathname + location.search });
  }, [location]);

  return (
    <AuthProvider>
      <Global>
        <AnimatedRoutes />
      </Global>
    </AuthProvider>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWrapper;
