import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./header-elements/Navbar";
import { getPageSettingProperty } from "../../../utils/pageSettings";

const Header = ({ className }) => {
  const { pathname, search } = useLocation();
  const { stage, seller } = Object.fromEntries(new URLSearchParams(search));
  const numericStage = stage ? Number(stage) : null;

  const headerElements = {
    setOne: {
      itemOne: { text: "Estimate", step: 1, stages: [1] },
      itemTwo: { text: "Review", step: 2, stages: [2] },
      itemThree: { text: "Schedule", step: 3, stages: [3, 4] },
    },
    setTwo: {
      itemOne: { text: "Pending", step: 1, stages: [5] },
      itemTwo: { text: "Scheduled", step: 2, stages: [6] },
      itemThree: { text: "Delivered", step: 3, stages: [7] },
    },
  };

  const toRender =
    numericStage <= 4 ? headerElements.setOne : headerElements.setTwo;

  const showStepper = getPageSettingProperty(pathname, "showStepper");

  const renderListItems = (elements) => {
    return Object.entries(elements).map(([key, value]) => (
      <li
        key={key}
        className={`flex items-center ${
          value.stages.includes(numericStage)
            ? "text-blue-600 dark:text-blue-500"
            : ""
        }`}
      >
        <span
          className={`hidden md:flex items-center justify-center w-5 h-5 me-2 text-xs border ${
            value.stages.includes(numericStage)
              ? "border-blue-600"
              : "border-gray-500"
          } rounded-full shrink-0 ${
            value.stages.includes(numericStage)
              ? "dark:border-blue-500"
              : "dark:border-gray-400"
          }`}
        >
          {value.step}
        </span>
        {value.text}
        {key !== "itemThree" && (
          <svg
            className={`w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180 ${
              value.stages.includes(numericStage)
                ? "text-blue-600 dark:text-blue-500"
                : "text-gray-500 dark:text-gray-400"
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 12 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m7 9 4-4-4-4M1 9l4-4-4-4"
            />
          </svg>
        )}
      </li>
    ));
  };

  return (
    <div className={`relative bg-white dark:bg-gray-900 mx-4 ${className}`}>
      <div className="w-full max-w-screen-xl mx-auto">
        <Navbar showStepper={showStepper} />
        <hr className="border-gray-200 sm:mx-auto dark:border-gray-700" />
        {showStepper && !seller && (
          <ol className="absolute top-0 left-0 right-0 flex justify-center space-x-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base dark:bg-gray-800 sm:space-x-4 rtl:space-x-reverse z-10 mt-5 pointer-events-auto">
            {renderListItems(toRender)}
          </ol>
        )}
      </div>
    </div>
  );
};

export default Header;
