import React from "react";

const SmLightText = ({ children, className }) => {
  return (
    <div className={`text-sm text-gray-500 dark:text-gray-400 ${className}`}>
      {children}
    </div>
  );
};

export default SmLightText;
