export function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters from the input
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the cleaned number has the correct length for a 10-digit number or an 11-digit number with the country code
  if (cleaned.length === 11 && cleaned.startsWith("1")) {
    // Format the 11-digit number with the country code
    return `+${cleaned[0]} ${cleaned.slice(1, 4)}-${cleaned.slice(
      4,
      7
    )}-${cleaned.slice(7)}`;
  } else if (cleaned.length === 10) {
    // Format the 10-digit number without the country code
    return `+1 ${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  } else {
    // Return the original input if the format is not as expected
    return phoneNumber;
  }
}
