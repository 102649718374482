import React, { createContext, useState, useEffect } from "react";
import { decodeToken } from "./jwtAuth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const handleAuthStateChange = () => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const decoded = decodeToken(token);
        if (decoded && decoded.uuid) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      } else {
        setLoggedIn(false);
      }
    };

    // Check the token on initial render
    handleAuthStateChange();

    // Listen for auth state changes
    window.addEventListener("authStateChanged", handleAuthStateChange);

    return () => {
      window.removeEventListener("authStateChanged", handleAuthStateChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
